<template>
  <div id="app">
    <FormWindow v-if="showContact" @close="showContact = false" />
    <ModalWindow
      v-for="(modal, index) in modals"
      :key="modal.id"
      @close="remove(index)"
      :img="modal.img"
      :width="modal.width"
      :height="modal.height"
      :zIndex="index"
      :tOffset="modal.tOffset"
      :lOffset="modal.lOffset"
      :active="index == modals.length - 1"
      @click="moveToFront(index)"
    />
    <button
      class="visible-btn"
      :disabled="modals.length >= maxModals || loading || showContact"
      @click="add()"
    >
      {{ workText }}
    </button>
    <div class="loading" v-if="loading" ref="loading">
      <h2>LOADING</h2>
    </div>
    <div class="header-logo"><img src="./assets/R78Logo.png" alt="logo" /></div>
    <button class="contact-btn" @click="showContact = !showContact">
      {{ contactText }}
    </button>
    <p class="footer-text">© {{ currentYear }} REDSEVENTYEIGHT</p>
    <a class="ig" href="https://www.instagram.com/redseventyeight/" target="_blank"><img src="./assets/ig.png" alt="ig" /></a>
  </div>
</template>

<script>
import ModalWindow from "./components/ModalWindow.vue";
import FormWindow from "./components/FormWindow.vue";

import imageList from "./firebase.js";
import { getDownloadURL } from "firebase/storage";

export default {
  name: "App",
  components: {
    ModalWindow,
    FormWindow,
  },
  data() {
    return {
      modals: [],
      tempModals: [],
      maxModals: 20,
      loading: false,
      showContact: false,
      deleting: false,
      lastModalLength: 0,
      empty: false,
      lastImageCount: 0,
      height: 0,
      width: 0,
    };
  },
  mounted() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },

  unmounted() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    // eslint-disable-next-line
    resizeHandler(e)  {
        this.height =  window.innerHeight;
        this.width =  window.innerWidth;
        console.log(this.height, this.width)
    },
    remove(index) {
      this.empty = false;
      this.deleting = true;
      this.lastModalLength = this.modals.length;
      this.modals.splice(index, 1);
      setTimeout(() => {
        this.deleting = false;
      }, 100);
    },
    add() {
      if (this.modals.length >= this.maxModals) return;
      this.loading = true;
      this.lastModalLength = this.modals.length;
      let width = 0;
      let height = 0;
      this.fetchRandomImage().then((image) => {
        if (!image.fullPath) {
          this.loading = false;
          this.empty = true;
          return;
        }
        getDownloadURL(image).then((url) => {
          let i = new Image();
          i.src = url;
          i.onload = () => {
            width = this.width > 600 ? i.width : i.width / 2;
            height = this.width > 600? i.height : i.height / 2;
            this.modals.push({
              id: this.modals.length,
              img: url,
              width: width,
              height: height,
              tOffset: Math.floor(Math.random() * 100),
              lOffset: Math.floor(Math.random() * 100),
              path: image.fullPath,
            });
            if (this.modals.length === this.lastImageCount) this.empty = true;
            setTimeout(() => {
              this.loading = false;
            }, 100);
          };
        });
      });
    },
    moveToFront(index) {
      if (index == this.modals.length - 1) return;
      if (this.deleting) return;
      const modal = this.modals.splice(index, 1);
      this.modals.push(modal[0]);
    },
    async fetchRandomImage() {
      let images = [];
      const response = await imageList;
      // remove image from array if it already exists in our modals array
      this.lastImageCount = response.items.length;
      images = response.items.filter((image) => {
        return !this.modals.some((modal) => {
          return modal.path == image.fullPath;
        });
      });
      if (images.length == 0) return {};
      this.empty = false;
      const img = images[Math.floor(Math.random() * images.length)];
      return img;
    },
  },
  computed: {
    workText() {
      if (this.empty) return "No More";
      if (this.modals.length == 0) return "Work";
      if (this.modals.length < this.maxModals) return "More";
      if (this.modals.length >= this.maxModals) return "Maxed Out";
      return "Work";
    },
    contactText() {
      if (this.showContact) return "Close";
      return "Collab";
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style>
@font-face {
  font-family: "kanit";
  src: url("./assets/kanit.woff") format("woff");
}

@font-face {
  font-family: "kanit-bold";
  src: url("./assets/kanit-bold.woff") format("woff");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "kanit-bold";
  color: #1e1e1d;
}

p,
label,
input,
textarea,
button {
  font-family: "kanit";
  color: #1e1e1d;
}

body {
  margin: 0;
  padding: 0;
  text-align: center;
  align-content: center;
  overflow: hidden;
  background: linear-gradient(180deg, #bebcba 0%, #e6e4e3 50%, #fdfbf8 100%);
}

#app {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
}

button:hover {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

.visible-btn {
  position: absolute;
  top: 0;
  left: 5px;
  z-index: 99;
  animation: pulse 2s infinite;
}

.contact-btn {
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 99999;
}

.ig {
  position: absolute;
  bottom: 15px;
  right: 5px;
  z-index: 99;
  animation: pulse 2s infinite;
  width: 30px;
}

.ig img {
  width: 100%;
}

@media (max-width: 600px) {
  .visible-btn {
    filter: blur(1px);
  }

  .contact-btn {
    filter: blur(1px);
  }

  .ig {
    filter: blur(1px);
  }
}

@media (min-width: 601px) {
  .visible-btn {
    filter: blur(4px);
  }

  .contact-btn {
    filter: blur(4px);
  }
  
  .ig {
    filter: blur(4px);
  }
}

button {
  background: none;
  border: none;
  color: #1e1e1d;
  outline: none;
  font-size: 1.5rem;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  transition: 0.2s;
}

button:hover {
  filter: blur(0px);
}

.ig:hover {
  filter: blur(0px);
  cursor: pointer;
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  animation: fadeblurin 1s;
}

.header-logo {
  position: absolute;
  left: 50%;
  top: 8%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  animation: fadeblurin 1s;
  mix-blend-mode: difference;
}

.header-logo img {
  height: 100px;
  filter: invert(1);
  mix-blend-mode: difference;
}

.footer-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: #1e1e1d;
  z-index: 98;
  filter: invert(1);
  mix-blend-mode: difference;
}

a {
  color: #1e1e1d;
}

a:hover {
  color: #3b3b3b;
}

a:visited {
  color: #1e1e1d;
}

@keyframes fadeblurin {
  0% {
    opacity: 0;
    filter: blur(10px);
  }

  100% {
    opacity: 1;
    filter: blur(0px);
  }
}

@keyframes fadeblurinlowopac {
  0% {
    opacity: 0;
    filter: blur(10px);
  }

  100% {
    opacity: 0.8;
    filter: blur(0px);
  }
}

@keyframes raise {
  0% {
    transform: translate(0, 50%);
  }

  50% {
    transform: translate(0, 38%);
  }

  100% {
    transform: translate(0, 8%);
  }
}

@keyframes lower {
  0% {
    transform: translate(0, 8%);
  }

  50% {
    transform: translate(0, 20%);
  }

  100% {
    transform: translate(0, 50%);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
</style>
