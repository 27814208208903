<template>
  <div id="contact-form">
    <div class="form" v-if="!sent">
      <h3>Let's collab! Please let me know about your project and I will get back to you.</h3>
      <!-- <button class="btn top-left" @click="$emit(`close`)">X</button> -->
      <input type="text" placeholder="Name" v-model="name" />
      <input type="text" placeholder="Email" v-model="email" />
      <input type="text" placeholder="Phone" v-model="phone" />
      <p>Budget: {{ budget }}</p>
      <input type="range" min="0" max="5000" class="slider" v-model="budgetVal">
      <p>Project Needs: {{ scope }}</p>
      <input type="range" min="0" max="100" class="slider" v-model="scopeVal">
      <textarea placeholder="Project Details" v-model="details" rows="6"></textarea>
      <button class="" :disabled="submitDisabled" @click="submit()">Submit</button>
      <p class="error"> {{ error }}</p>
    </div>
    <div class="form" v-if="sent">
      <h3>Thank you for your interest! I will get back to you shortly.</h3>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: "ComponentName",
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      details: "",
      scopeVal: 0,
      budgetVal: 0,
      sent: false
    };
  },
  methods: {
    submit() {
      try {
        emailjs.send('service_p8uh0l8', 'template_ysm9czi', {
          name: this.name,
          email: this.email,
          message: this.name + "\n" + this.email + "\n" + this.phone + "\n\n" + this.details + "\n\n" + this.scope + "\n" + this.budget
        }, "oEp3EsbHOxJU0T_V-")
        this.sent = true;
      } catch (error) {
        console.log({ error })
        return;
      }
      // Reset form field
      this.name = ""
      this.email = ""
      this.phone = ""
      this.details = ""
      this.scopeVal = 0
      this.budgetVal = 0
    }
  },
  computed: {
    submitDisabled() {
      return this.name.length < 3 || !this.validEmail || !this.validPhone || this.details.length < 10;
    },
    scope() {
      if (this.scopeVal < 25) {
        return "Photography";
      } else if (this.scopeVal < 50) {
        return "Videography";
      } else if (this.scopeVal < 75) {
        return "Photography & Videography";
      } else {
        return "Unsure";
      }
    },
    budget() {
      if (this.budgetVal < 5000)
        return `$${this.budgetVal}`
      return "Unsure";
    },
    validEmail() {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      return emailRegex.test(this.email);
    },
    validPhone() {
      const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      return phoneRegex.test(this.phone);
    },
    error() {
      if (this.name.length < 3) {
        return "Please enter a valid name.";
      } else if (!this.validEmail) {
        return "Please enter a valid email.";
      } else if (!this.validPhone) {
        return "Please enter a valid phone number.";
      } else if (this.details.length < 10) {
        return "Please enter a valid message.";
      } else {
        return "";
      }
    }
  },
};
</script>

<style scoped>
#contact-form {
  z-index: 9999;
  position: relative;
  animation: fadeblurin 1s both;
  height: 100%;
}

@media (max-width: 600px) {
  .form {
    z-index: 9999;
    width: 500px;
    max-width: 90%;
    min-height: 300px;
    max-height: 75%;
    overflow-y: auto;
    margin: 0 auto;
    margin-top: 75px;
    border: none;
    outline: none;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 10%;
  }
}

@media (min-width: 601px) {
  .form {
    position: absolute;
    z-index: 9999;
    top: 55px;
    right: 0;
    width: 500px;
    max-width: 90%;
    min-height: 300px;
    max-height: 90%;
    overflow-y: auto;
    border: none;
    outline: none;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }
}


.form h3 {
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.form input,
.form textarea,
.form button {
  width: 80%;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 1rem;
  font-family: "kanit";
  color: #1e1e1d;
  margin: 0 auto;
  margin-top: 10px;
  resize: none;
}

.bigger {
  padding: 10px;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.top-right {
  position: absolute;
  top: -5px;
  right: 0;
  padding-right: 5px;
}

.top-left {
  position: absolute;
  top: -55px;
  left: 0;
  padding-left: 5px;
}

.btn {
  background: none;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0 0 3px 0;
  max-width: 10px;
}

.btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.btn:hover {
  opacity: 1;
}

.btn:hover:disabled {
  opacity: 0.6;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #a7a7a7;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
  /* Fully shown on mouse-over */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #1e1e1d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #1e1e1d;
  cursor: pointer;
}
</style>
