import { initializeApp } from "firebase/app";
import { getStorage, ref, listAll } from "firebase/storage";

/* eslint-disable */

const firebaseConfig = {
  apiKey: "AIzaSyD7CjW3FBAo_PIdvSebybptpkE2OJfRFBA",
  authDomain: "redseventyeight-3443c.firebaseapp.com",
  projectId: "redseventyeight-3443c",
  storageBucket: "redseventyeight-3443c.appspot.com",
  messagingSenderId: "931840162998",
  appId: "1:931840162998:web:08fe575f3a6c0ab0733a17"
};

const app = initializeApp(firebaseConfig);

const storage = getStorage();
const listRef = ref(storage, 'images/');

const imageList = listAll(listRef);

export default imageList;