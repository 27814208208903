<template>
    <VueDragResize
      :isActive="true"
      :parentLimitation="true"
      :minw="minw"
      :minh="minh"
      :w="width"
      :h="height"
      :x="windowWidth / 2 - (width/2) + lOffset"
      :y="windowHeight / 8 + tOffset"
      :z="zIndex"
      :contentClass="`modal`"
      :isResizable="false"
      :class="`${initialized ? '' : 'init'}`"
      @mousedown="$emit('click')"
      @click="$emit('click')"
    >
      <div class="inner" :style="{'background-image': `url(${img})`}">
        <button class="btn" @mouseup="$emit('close')" @click="$emit('close')">X</button>
      </div>
    </VueDragResize>
</template>

<script>
import VueDragResize from "vue3-drag-resize";

export default {
  name: "MyComponent",
  components: {
    VueDragResize,
  },
  data() {
    return {
      minw: 200,
      minh: 100,
      top: this.tOffset | 0,
      left: this.lOffset | 0,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      initialized: false
    };
  },
  props: {
    img: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    zIndex: {
      type: Number,
      required: true,
    },
    tOffset: {
      type: Number,
      required: true,
    },
    lOffset: {
      type: Number,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    }
  },
  methods: {
    resize(newRect) {
      this.top = newRect.top;
      this.left = newRect.left;
    },
  },
  computed: {
    // Your component's computed properties go here
  },
  mounted() {
    setTimeout(() => {
      this.initialized = true;
      console.log(this.initialized);
    }, 500);
  },
};
</script>

<style scoped>
.modal {
  background: #dcd9d5;
  border: none;
  outline: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  opacity: .8;
  cursor: move;
}

.init {
  animation: fadeblurinlowopac 0.5s;
}

.vdr.active:before {
  outline: none;
  border-radius: 3px;
}

.header {
  width: 100%;
  margin-top: 0 auto;
  display: flex;
  justify-content: space-between;
  border-radius: 3px 3px 0 0;
  z-index: 99;
}

.header .btn {
  float: right;
  margin: 5px;
}

.header h3 {
  margin: 5px;
}

.inner {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  height: calc(100%);
  background-size: cover;
  border-radius: 3px;
}

@media (max-width: 600px) {
  .btn {
    font-size: 1.5rem;
    padding: 10px;
  }
}

@media (min-width: 601px) {
  .btn {
    font-size: 1rem;
    padding-right: 5px;
  }
}
.btn {
  background: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  float:left;
  filter: invert(1);
  mix-blend-mode: difference;
  border-radius: 0 0 3px 0;
  z-index: 99999;
}

.btn:disabled {
  cursor: not-allowed;
}

.btn:hover {
  opacity: .6;
}
</style>
